export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export function formatDate(dateString, output = 'fulldate') {
  const date = new Date(dateString)

  if (output === 'yearonly') {
    return date.getFullYear()
  }

  const day = date.getDate()
  const month = date.toLocaleString('default', { month: 'short' })
  const year = date.getFullYear().toString().slice(-2)

  return `${day}${formatDateGetOrdinal(day)} ${month} '${year}`
}

function formatDateGetOrdinal(day) {
  if (day > 3 && day < 21) return 'th'
  switch (day % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export async function triggerVercelBuild() {
  const res = await fetch(
    'https://api.vercel.com/v1/integrations/deploy/prj_nLp1NpI9Tug4y9ur3yYoHjwtWIm7/I36uLF1y22'
  )
  const json = await res.json()
  alert(
    `Build hook triggered, status ${json.job.state}. Full response logged in console.`
  )
  console.log(json.job)
}

export function setCookie(name, value, days) {
  let expires = ''
  if (days) {
    let date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export function getCookie(name) {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
}
